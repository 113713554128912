import React from "react";
import { Link } from "react-router-dom";
import "./Nopage.css";

const Nopage = () => {
  return (
    <section className="noPage">
      <div id="notfound">
        <div className="notfound-bg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Page Not Found</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <Link to="https://thekhaitanschool.cuet.testknock.com/">Homepage</Link>
          <div className="notfound-social d-none">
            <a href="https://www.facebook.com/thekhaitanschool">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="https://www.youtube.com/c/TheKhaitanSchoolNoida/videos">
              <i className="fa fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/thekhaitanschool/">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/tksnoida">
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nopage;