import React from 'react';
import { FaArrowRight, FaRegBuilding, FaBook } from 'react-icons/fa';
import { IoIosTimer, IoMdCall, IoMdMail, IoMdPin } from 'react-icons/io';
import { Link } from 'react-router-dom';
import logo from '../assets/tks-logo.png';

// Contact and Curriculum Data
const contacts = [
  {
    label: 'For general enquiry',
    email: 'info@thekhaitanschool.org',
  },
  {
    label: 'For admission queries',
    email: 'admissions@thekhaitanschool.org',
  },
];

const phoneNumbers = [
  {
    label: 'Reception',
    number: '+91-120-400-7575',
  },
  {
    label: 'Admissions',
    number: '+91-120-400-7551/543',
  },
];

const socialLinks = [
  {
    href: 'https://www.youtube.com/c/TheKhaitanSchoolNoida/videos',
    src: require('../assets/icons/ytb.png'),
    alt: 'YouTube',
    className: 'h-5',
  },
  {
    href: 'https://www.instagram.com/thekhaitanschool/',
    src: require('../assets/icons/instagram-logo.png'),
    alt: 'Instagram',
    className: 'h-[35px] w-[35px]',
  },
  {
    href: 'https://www.facebook.com/thekhaitanschool',
    src: require('../assets/icons/facebook.png'),
    alt: 'Facebook',
    className: 'h-6',
  },
  {
    href: 'https://www.linkedin.com/company/tksnoida',
    src: require('../assets/icons/linkedin.png'),
    alt: 'LinkedIn',
    className: 'h-6',
  },
  {
    href: 'https://www.google.com/maps?ll=28.565171,77.351974&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=17390485997308483397',
    src: require('../assets/icons/map.png'),
    alt: 'Map',
    className: 'h-6',
  },
];

const admissionLinks = [
  {
    name: 'Apply Now',
    href: 'https://thekhaitanschool.org/admission-enquiry/',
  },
];

const curriculumData = [
  {
    title: "ECCE",
    description: "Early Childhood Care & Education",
    href: "https://thekhaitanschool.org/pre-primary-2/",
  },
  {
    title: "Primary",
    description: "Primary School Curriculum",
    href: "https://thekhaitanschool.org/primary-school-curriculum/",
  },
  {
    title: "Middle & Senior",
    description: "Middle & Senior School Curriculum",
    href: "https://thekhaitanschool.org/curriculum/middle-senior-school-curriculum/",
  },
];

const technicalSupport = {
  company: 'TestKnock Technologies',
  email: 'info@testknock.com',
  phone: '+91-9560443520',
  address: `Office No. - 42, Durga Vihar, near Amrapali Sapphire, Sec-45, Noida-201301`,
};

const supportLinks = [
  {
    name: 'Terms & Conditions',
    href: '/Terms and Conditions for TestKnock.pdf',
  },
  {
    name: 'Cancellation/Refund Policy',
    href: '/Cancellation and Refund Policy for TestKnock.pdf',
  },
  {
    name: 'Privacy Policy',
    href: '/Privacy Policy for TestKnock.pdf',
  },
];

const Footer = () => {
  return (
    <footer className="bg-black text-white w-full py-10 flex justify-center items-center">
      <div className="w-full px-5">
        <div className="flex flex-col md:flex-row md:gap-8">
          {/* Left Section: Logo and Description */}
          <div className="md:w-1/3 flex flex-col">
            <Link to="/" className="flex items-center mt-4">
              <img src={logo} alt="Logo" className="h-16 w-16 pr-4 pb-2 mr-2" />
              <h2 className="text-white font-bold text-lg">THE KHAITAN SCHOOL</h2>
            </Link>
            <p className="mt-4 text-justify">
              "Founded in April 1995, we are a 21st-century ready, leading CBSE school in Noida. Our international school engagements have helped children connect with peers from various countries such as the USA, France, Japan, Korea & Germany, ensuring a truly global school experience. Education World Magazine & TOI surveys have consistently ranked us amongst the top 10 schools in Noida..."
            </p>
            <a
              target="_blank"
              href="https://thekhaitanschool.org/"
              className="mt-4 inline-flex items-center bg-white text-black p-3 rounded-tl-2xl rounded-br-2xl"
              rel="noreferrer"
            >
              Learn More <FaArrowRight className="ml-2" />
            </a>
          </div>

          {/* Right Section */}
          <div className="md:w-2/3 grid md:grid-cols-3 gap-4">
            {/* Contact Us */}
            <div className="mx-auto">
              <p className="font-bold mb-4">Contact Us</p>
              <a
                href="https://www.google.com/maps?ll=28.565171,77.351974&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=17390485997308483397"
                target="_blank"
                rel="noreferrer"
                className="flex items-start text-white my-3 hover:text-blue-500"
              >
                <IoMdPin className="text-lg mr-2  h-[40px] w-[40px]" />
                <span>THE KHAITAN SCHOOL, 1A/A, Block – F, Sector 40, Noida – 201303, U.P.</span>
              </a>
              <div className="flex items-start my-3">
                <IoMdCall className="text-lg mr-2 mt-2" />
                <div>
                  {phoneNumbers.map((phone, index) => (
                    <div key={index} className="my-1">
                      <a href={`tel:${phone.number.replace(/[^+\d]/g, '')}`} className="hover:text-blue-500 text-white">
                        {phone.label}: {phone.number}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-start my-3 hover:cursor-default">
                <IoIosTimer className="text-lg mr-2" />
                <span>Mon - Sat: 8.00 AM - 04.00 PM</span>
              </div>
              <div className="flex items-start my-3">
                <IoMdMail className="text-lg mr-2 mt-1 h-[40px] w-[40px]" />
                <div>
                  {contacts.map((contact, index) => (
                    <div key={index} className="my-1">
                      <span>{contact.label}: <a href={`mailto:${contact.email}`} className="hover:text-blue-500 text-blue-300">{contact.email}</a></span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-6 mt-4 items-center">
                {socialLinks.map((link, index) => (
                  <a key={index} href={link.href} target="_blank" rel="noreferrer">
                    <img src={link.src} alt={link.alt} className={link.className} />
                  </a>
                ))}
              </div>
            </div>

            {/* Curriculum and Admissions */}
            <div className="mx-auto">
              <p className="font-bold mb-4">Curriculum</p>
              <div className="grid md:grid-cols-1">
                {curriculumData.map((curriculum, index) => (
                    <div
                    key={index}
                    className="bg-white p-2 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-100 cursor-pointer transform mb-4 w-full"
                  >
                    <div className="flex items-center max-h-[20px]">
                      <FaBook className="text-blue-500 text-2xl mr-3" />
                      <a
                      href={curriculum.href}
                      className="text-blue-500 inline-flex items-center font-semibold hover:text-blue-700 transition-colors duration-300"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="font-semibold text-xl">{curriculum.title}</span>
                      </a>
                    </div>
                    </div>
                ))}
              </div>
              <hr className="my-b-4 border-gray-300" />
              <div className="text-center">
                <p className="font-bold text-lg mb-4">Admissions</p>
                <div className="flex justify-center space-x-4">
                  {admissionLinks.map((link, index) => (
                    <a
                      key={index}
                      href={link.href}
                      className="text-white bg-blue-500 px-6 py-3 rounded-full font-semibold shadow hover:bg-white transition-all duration-300"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.name}
                    </a>
                  ))}
                </div>
                <p className="mt-6">
                  <a
                    href="https://thekhaitanschool.org/privacy-policy/"
                    className="text-gray-500 hover:text-gray-800 transition-colors duration-300"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>

          {/* Technical Support */}
          <div className="mx-auto">
            <p className="font-bold mb-4">Any Technical Issues</p>
            <a
              href="https://www.testknock.com/"
              target="_blank"
              rel="noreferrer"
              className="flex items-start my-6 hover:text-blue-500 text-white"
            >
              <FaRegBuilding className="text-lg mr-2 mt-1" />
              {technicalSupport.company}
            </a>
            <div className="flex items-start my-6">
              <IoMdMail className="text-lg mr-2 mt-1" />
              <a
                href={`mailto:${technicalSupport.email}`}
                className="hover:text-blue-500 text-white"
              >
                {technicalSupport.email}
              </a>
            </div>
            <div className="flex items-start my-6">
              <IoMdCall className="text-lg mr-2 mt-1" />
              <a
                href={`tel:${technicalSupport.phone.replace(/[^+\d]/g, '')}`}
                className="hover:text-blue-500 text-white"
              >
                {technicalSupport.phone}
              </a>
            </div>
            <a
              href="https://goo.gl/maps/TestKnockLocation"
              target="_blank"
              rel="noreferrer"
              className="flex items-start my-6 hover:text-blue-500 text-white"
            >
              <IoMdPin className="text-lg mr-2 mt-1 h-[40px] w-[40px]" />
              <span>{technicalSupport.address}</span>
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-6">
        <hr className="w-full text-white" />
        <div className="w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center mt-3 text-sm">
          <div className="flex items-center mb-2 md:mb-0">
            &copy; 2024 <b className="ml-2">TestKnock Technologies Private Limited</b>
            <b className="ml-4">All rights reserved</b>
          </div>
          <div className="flex items-center gap-4">
            {supportLinks.map((link, index) => (
              <React.Fragment key={index}>
                <a
                  href={link.href}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold hover:text-blue-500"
                >
                  {link.name}
                </a>
                {index < supportLinks.length - 1 && <b>|</b>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  </footer>
);
};

export default Footer;

