import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { CiMail } from "react-icons/ci";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { IoMdCall, IoMdMail, IoMdPin } from "react-icons/io";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { API } from '../utils/constants';

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.firstName.trim() || !formData.lastName.trim()) {
            formErrors.name = 'First name and last name are required';
            isValid = false;
        }

        if (!formData.email.trim()) {
            formErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Invalid email address';
            isValid = false;
        }

        if (!formData.phoneNumber.trim()) {
            formErrors.phoneNumber = 'Phone number is required';
            isValid = false;
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
            formErrors.phoneNumber = 'Invalid phone number';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            const response = await fetch(`${API}/contact/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    name: `${formData.firstName} ${formData.lastName}`,
                }),
            });
            if (response.ok) {
                console.log('Form submitted successfully!');
            } else {
                console.error('Form submission error:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="max-w-[1380px] items-center grid min-h-screen mx-auto">
                <div className="my-10 mb-16">
                    <div className="text-center">
                        <h2 className='text-[#FF820F] md:my-[-10px] font-extrabold text-17xl'>Contact Us</h2>
                        <p className='opacity-[100%] text-[#717171] font-semibold max-md:mx-10'>Any question or remarks? Just write us a message!</p>
                    </div>
                    <div className="bg-white max-md:mx-[10px] mx-[80px] max-lg:mx-[20px] shadow-2xl rounded-md gap-5 flex-col flex md:flex-row">
                        <div className="bg-black md:w-[36%] relative h-[500px] max-md:items-center flex flex-col justify-between p-4 rounded-md m-2 text-white">
                            <div className='max-md:text-center max-md:text-[12px]'>
                                <h1>Contact Information</h1>
                                <p>Say something to start a chat!</p>
                            </div>
                            <div className="flex-col relative z-10 flex gap-2">
                                <a href="mailto:info@thekhaitanschool.org" className="hover:opacity-80 no-underline">
                                    <p className="md:text-center font-medium flex md:flex-row flex-col max-md:justify-center gap-2 items-center my-3 text-white hover:text-red-500 hover:cursor-pointer">
                                        <span className="flex justify-center items-center mr-2">
                                            <IoMdMail className="text-[1.1em] md:text-[1.5em]" />
                                        </span>
                                        info@thekhaitanschool.org
                                    </p>
                                </a>
                                <a href="tel:+91-120-4007575" className="hover:opacity-80 no-underline">
                                    <p className="max-md:text-center font-medium flex md:flex-row flex-col items-center md:w-[85%] max-md:justify-center gap-2 my-3 text-white hover:text-red-500 hover:cursor-pointer">
                                        <span className="flex justify-center items-center mr-2">
                                            <IoMdCall className="text-[1.1em] md:text-[1.5em]" />
                                        </span>
                                        +91-120-400-7540 / +91-120-400-7551
                                    </p>
                                </a>
                                <a href="https://maps.app.goo.gl/i8P3aFckmhVj2nKW9" className="hover:opacity-80 no-underline">
                                    <p className="md:text-center font-medium flex flex-col max-md:justify-center max-md:items-center gap-2 md:flex-row my-3 text-white hover:text-red-500 hover:cursor-pointer">
                                        <span className="flex">
                                            <IoMdPin className="text-[1.1em] md:text-[1.5em]" />
                                        </span>
                                        <span className="ml-2 text-center md:text-left">
                                            The Khaitan School, <br />
                                            Block – F, Sector 40, <br />
                                            Noida – 201303, U.P.
                                        </span>
                                    </p>
                                </a>
                            </div>
                            <div className="flex gap-2 relative z-10 m-4">
                                <a href='mailto:info@thekhaitanschool.org' className="bg-white flex justify-center items-center no-underline w-10 h-10 rounded-full">
                                    <CiMail className='text-[18px]' />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/thekhaitanschool/" className="bg-white flex justify-center no-underline items-center w-10 h-10 rounded-full text-black">
                                    <FaInstagram className='text-[18px]' />
                                </a>
                                <a href="tel:+91-120-4007551" className="bg-white flex justify-center w-10 h-10 rounded-full no-underline items-center">
                                    <FiPhoneCall className='text-[18px]' />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/thekhaitanschool" className="bg-white flex justify-center no-underline items-center w-10 h-10 rounded-full text-black">
                                    <FaFacebook className='text-[18px]' />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/tksnoida" className="bg-white flex justify-center no-underline items-center w-10 h-10 rounded-full text-black">
                                    <FaLinkedinIn className='text-[18px]' />
                                </a>
                            </div>
                            <img className="absolute rounded-md bottom-0 right-0 max-w-[120px] lg:max-w-[230px]" src={require("../assets/images/about/shadow.jpeg")} alt="" />
                        </div>

                        {/* Form Section */}
                        <form className="h-[420px] md:w-[64%] flex-col flex justify-between" onSubmit={handleSubmit}>
                            <div className="md:my-[40px] mx-[30px] m-3 md:mr-10">
                                <div className='grid md:grid-cols-2 my-[10px] gap-5'>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        variant="standard"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'medium',
                                                color: 'black',
                                            },
                                        }}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        variant="standard"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'medium',
                                                color: 'black',
                                            },
                                        }}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </div>
                                <div className='grid md:grid-cols-2 md:my-[30px] gap-5'>
                                    <TextField
                                        id="email"
                                        name="email"
                                        placeholder='Enter your Email'
                                        label="Email"
                                        variant="standard"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'medium',
                                                color: 'black',
                                            },
                                        }}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                    <TextField
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder='Enter your Contact'
                                        label="Phone Number"
                                        variant="standard"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'medium',
                                                color: 'black',
                                            },
                                        }}
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        style={{ width: "100%" }}
                                        id="message"
                                        name="message"
                                        placeholder='Write your message...'
                                        label="Message"
                                        variant="standard"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'medium',
                                                color: 'black',
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center md:justify-end pb-10  md:mr-10">
                                <button className="bg-black rounded-md text-white max-md:mx-[30px] px-5 max-md:w-full p-3" type="submit">
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
