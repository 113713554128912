import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { urlSanitizer } from "../../services/url-sanitization.service";
import { API } from "../../utils/constants";
import { useAuth } from "../../utils/context";
// import FixedNavbar from "../FixedNavbar";
import Footer from "../Footer";
import CuetLoader from "../Loader/Loader";
import NoData from "../Loader/NoData";
import Navbar from "../Navbar";
import PrepModulesMixedSubquestion from "./PrepModulesMixedSubquestion";
import PrepModulesMultipleSubquestion from "./PrepModulesMultipleSubquestion";
import PrepModulesSingleSubquestion from "./PrepModulesSingleSubquestion";
import RecommendedSubTopics from "./RecommendedSubTopics";
import "./question.css";

const PrepModules = () => {
  const [auth] = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { subject, topic, subTopic } = useParams();
  subject = urlSanitizer(subject);
  subTopic = urlSanitizer(subTopic);
  topic = urlSanitizer(topic);

  if (!subject) {
    subject = topic;
    topic = subTopic;
    subTopic = "";
  }
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const params = {
        subject: subject,
        topic: topic,
        subTopic: subTopic,
      };
      try {
        let response;
        response = await axios.get(
          `${API}/question/find-questions-new?userId=${auth?.user?._id}`,
          // `${API}/question/find-questions`,
          {
            params: params,
            headers: {
              Authorization: `Bearer ${auth.accessToken.token}`,
            },
          }
        );

        setData(response?.data?.requestedData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [subject, subTopic, topic, auth]);
  // }, [subject, subTopic, topic]);

  if (isLoading) {
    return <CuetLoader />;
  }

  const questionType = (questionData) => {
    let singleSubquestion = 0,
      multipleSubquestion = 0;
    for (let item of questionData) {
      if (
        item?.questionTextAndImages &&
        item.questionTextAndImages[0]?.text?.[0]
      ) {
        multipleSubquestion++;
      } else {
        singleSubquestion++;
      }
    }

    if (multipleSubquestion === questionData.length) {
      return "multiple";
    } else if (singleSubquestion === questionData.length) {
      return "single";
    } else {
      return "mixed";
    }
  };

  return (
    <section className="bg-white overflow-hidden">
      {/* <div className="max-md:hidden">{!auth?.user && <FixedNavbar />}</div> */}
      <Navbar />
      {data?.length > 0 ? (
        <div className="flex max-w-[1280px] mb-[4rem] md:flex-row flex-col max-md:px-5 mx-auto justify-center gap-[3vw] ">
          <div
            className="md:w-[30vw] ">
            <RecommendedSubTopics currentSubTopic={subTopic} />
          </div>
          <div className="md:w-[60vw]">
            {questionType(data) === "multiple" && (
              <PrepModulesMultipleSubquestion data={data} />
            )}
            {questionType(data) === "single" && (
              <PrepModulesSingleSubquestion data={data} />
            )}
            {questionType(data) === "mixed" && (
              <PrepModulesMixedSubquestion data={data} />
            )}
          </div>
        </div>
      ) : (
        <NoData />
      )}
      <Footer />
    </section>
  );
};

export default PrepModules;
